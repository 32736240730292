import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Relax } from 'plume2';
import { noop } from 'wmkit';
@Relax
export default class AccountFrom extends Component<any, any> {
  props: {
    relaxProps?: {
      isShowpwd: boolean;
      buttonstate: boolean;
      buttonvalue: string;
      account: string;
      password: string;
      showPass: Function;
      doLogin: Function;
      setAccount: Function;
      setPassword: Function;
    };
  };

  static relaxProps = {
    isShowpwd: 'isShowpwd',
    buttonstate: 'buttonstate',
    buttonvalue: 'buttonvalue',
    account: 'account',
    password: 'password',
    showPass: noop,
    doLogin: noop,
    setAccount: noop,
    setPassword: noop
  };

  render() {
    let {
      isShowpwd,
      buttonstate,
      buttonvalue,
      account,
      password,
      showPass,
      doLogin,
      setPassword
    } = this.props.relaxProps;
    if (account.length > 11) {
      account = account.substring(0, 11);
    }
    return (
      <div style={{ width: '90%' }}>
        <div className="login-item">
          <div className="inputBox">
            <div>
              <img
                src={require('../img/1.png')}
                style={{ width: '.5rem', height: '.5rem' }}
              />
              <input
                className="formInput"
                type="text"
                pattern="[A-Za-z0-9]*"
                placeholder="请输入账号"
                value={account}
                maxLength={20}
                onChange={(e) => this._getAccount(e)}
              />
            </div>
          </div>
        </div>
        <div className="login-item">
          <div className="inputBox eyes-box">
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img
                src={require('../img/2.png')}
                style={{ width: '.5rem', height: '.5rem' }}
              />
              <input
                className={`formInput ${!isShowpwd ? 'special' : ''}`}
                type={isShowpwd ? 'text' : 'password'}
                placeholder="密码"
                autoComplete="new-password"
                pattern="/^[A-Za-z0-9]{6,16}$/"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                maxLength={16}
              />
            </div>

            {/* <i
              onClick={() => showPass()}
              style={{ marginLeft: '1.2rem' }}
              className={`iconfont icon-${
                isShowpwd ? 'yanjing' : 'iconguanbiyanjing'
              }`}
            /> */}
            <img
              onClick={() => showPass()}
              src={
                isShowpwd ? require('../img/5.png') : require('../img/6.png')
              }
              style={{ width: '.5rem', height: '.5rem' }}
            />
          </div>
        </div>
        <div className="l-findCode">
          <a href="/user-safe-password">忘记密码</a>
        </div>
        <div className="login-btnBox">
          <button
            className={buttonstate ? 'btn-disabled login-btn' : 'login-btn'}
            disabled={buttonstate}
            onClick={() => doLogin()}
            style={styles.login}
          >
            登录
          </button>
        </div>
      </div>
    );
  }

  _getAccount = (e) => {
    const { setAccount, account } = this.props.relaxProps;
    let value = e.target.value;
    //圆点.特殊处理
    let lastchart = value.substr(value.length - 1, 1);
    if (
      // value.toString() % 1 != 0 ||
      lastchart == '.' ||
      lastchart == '+' ||
      lastchart == '-'
    ) {
      setAccount(account);
    } else {
      if (value.length > 11) {
        setAccount(account);
      } else {
        setAccount(value);
      }
    }
  };
}
const styles = {
  login: {
    background: 'linear-gradient(to right, #bd2d27, #eb6d4e) '
  }
};
