import React from 'react';
import ReactDOM from 'react-dom';
import 'wmkit/common.css';
import { Provider } from 'react-redux';
import '../web_modules/wmkit/wm_sta.js';
import { Route, Router } from 'react-router-dom';
import 'babel-polyfill';
import { routers } from './router';
import Footer from './footer';
import { BStoreClose, RegisterCoupon, StoreClose } from 'biz';
import wx from 'weixin-js-sdk';
import { history, routeWithSubRoutes, WMkit, WMLoginModal } from 'wmkit';
import Fetch from 'wmkit/fetch';
import store from './redux/store';
import { config } from 'config';

const D2CWechat = () => (
  <Provider store={store}>
    <Router history={history}>
      <Route component={Container} />
    </Router>
  </Provider>
);

const urlShareString = 'shareUserId=';

class Container extends React.Component<any, any> {
  state = {
    visible: true,
    path: '/main'
  };

  componentWillMount() {
    if (typeof localStorage === 'object') {
      try {
        localStorage.setItem('localStorage666', '1');
        localStorage.removeItem('localStorage666');
      } catch (e) {
        alert(
          '浏览器可能开启了无痕浏览模式，影响本网站功能的正常使用，请切换为普通模式'
        );
      }
    }

    Fetch('/system/baseConfig').then((res) => {
      if (res.code == config.SUCCESS_CODE) {
        const ico = (res.context as any).pcIco
          ? JSON.parse((res.context as any).pcIco)
          : null;
        if (ico) {
          let linkEle = document.getElementById('icoLink') as any;
          linkEle.href = ico[0].url;
          linkEle.type = 'image/x-icon';
        }
      }
    });
    wx.miniProgram.getEnv(function(res) {
      (window as any).isMiniProgram = res.miniprogram;
    });
  }

  render() {
    /**是否包含分享人标识**/
    if (location.href.indexOf(urlShareString) > -1) {
      let param = location.href.split('shareUserId=')[1];
      const shareUserId = param.split('&')[0];
      localStorage.setItem('shareUserId', shareUserId);
      sessionStorage.setItem('shareUserId', shareUserId);
    }

    /**收集非商品页pv/uv*/
    if (!this._isRelateGoodsPage()) {
      (window as any).myPvUvStatis();
    }

    const routes = routeWithSubRoutes(routers, this.handlePathMatched);
    const { history } = this.props;

    return (
      <div>
        {routes}
        {this.state.visible ? (
          <Footer path={this.state.path} history={history} />
        ) : null}
        <StoreClose />
        <BStoreClose />
        <RegisterCoupon />
        <WMLoginModal />
      </div>
    );
  }

  handlePathMatched = ({ path, hasBottom }) => {
    hasBottom || path === '/main'
      ? this.setState({ visible: true, path: path })
      : this.setState({ visible: false, path: path });
  };

  /**
   * 是否为 商品相关的页面
   */
  _isRelateGoodsPage = () => {
    //商品详情,店铺主页,店铺分类,店铺档案,店铺会员,店铺搜索页,店铺商品列表
    const urlArr = [
      'goods-detail/',
      'store-main/',
      'store-goods-cates/',
      'store-profile/',
      'member-shop/',
      'store-goods-search/',
      'store-goods-list/'
    ];
    //判断当前链接是否为商品相关页面
    return urlArr.findIndex((url) => location.href.indexOf(url) > -1) > -1;
  };
}

ReactDOM.render(<D2CWechat />, document.getElementById('app'));
