import React from 'react';

const noop = () => {};

export interface IFormSelectProps {
  //项目名
  labelName?: string;
  required?: boolean;
  onPress?: Function;
  // 选中后的值
  selected?: ISelected;
  placeholder?: string;
  textStyle?: Object;
  //icon 是否显示
  iconVisible?: boolean;
  disabledFlag?: boolean;
  styleItem?: Object;
  jianStyle?: Object;
}

//选终值
export interface ISelected {
  key: any;
  value: string;
}

/**
 * form中选中
 */
export default class FormSelect extends React.Component<IFormSelectProps, any> {
  static defaultProps = {
    onPress: noop,
    iconVisible: true
  };
  constructor(props: IFormSelectProps) {
    super(props);
  }

  render() {
    const {
      required,
      labelName,
      onPress,
      selected,
      placeholder,
      textStyle,
      iconVisible,
      disabledFlag,
      styleItem,
      jianStyle
    } = this.props;
    return (
      <div
        className="row form-item"
        onClick={() => onPress()}
        style={styleItem ? styleItem : {}}
      >
        <span className="form-text">
          {required && <span style={{ color: '#bd2d27' }}>*</span>}
          {labelName}
        </span>
        <div className="select-right">
          <span className="select-text" style={textStyle}>
            {disabledFlag ? (
              <span style={{ color: '#999' }}>
                {selected && selected.value ? selected.value : placeholder}
              </span>
            ) : selected && selected.value ? (
              selected.value
            ) : (
              placeholder
            )}
          </span>
          {iconVisible ? (
            <i className="iconfont icon-jiantou1" style={jianStyle} />
          ) : null}
        </div>
      </div>
    );
  }
}
