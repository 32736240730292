import React from 'react';
import ReactDOM from 'react-dom';
import Dialog, { DialogProps } from './dialog';
import AlertD, { AlertProps } from './alert';
import ConfirmD, { ConfirmProps } from './confirm';
import ActionSheetD, { ActionSheetProps } from './actionSheet';

/**
 * 各种弹窗
 * @param paras
 */
const Alert = (paras: AlertProps) => {
  const { div, close } = createDiv(paras && paras.close);
  ReactDOM.render(<AlertD {...paras} close={close} />, div);
};

const Confirm = (paras: ConfirmProps) => {
  const { div, close } = createDiv(paras && paras.close);
  ReactDOM.render(<ConfirmD {...paras} close={close} />, div);
};

const Modal = (paras: DialogProps) => {
  const { div, close } = createDiv(paras && paras.close);
  ReactDOM.render(<Dialog {...paras} close={close} />, div);
};

const ActionSheet = (paras: ActionSheetProps) => {
  const { div, close } = createDiv(paras && paras.close);
  ReactDOM.render(<ActionSheetD {...paras} close={close} />, div);
};

export { Alert, Modal, Confirm, ActionSheet };

//body追加dialog
function createDiv(paramsClose: Function) {
  let div = document.createElement('div');
  document.body.appendChild(div);

  //关闭弹窗
  function close() {
    ReactDOM.unmountComponentAtNode(div);
    div.parentNode.removeChild(div);
    paramsClose && paramsClose();
  }
  return { div, close };
}
