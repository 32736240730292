import React, { Component } from 'react';
import { Relax } from 'plume2';
import { noop,_ } from 'wmkit';
@Relax
export default class QuickLogin extends Component<any, any> {
  static relaxProps = {
    weChatQuickLogin: noop
  };

  render() {
    const { weChatQuickLogin } = this.props.relaxProps;
    return (
      <div className="loginList">
        {_.isWeixin() && !_.isWorkWeixin() && (
          <a href="javascript:;" onClick={() => weChatQuickLogin()}>
            <img src={require('../img/weChat.png')} alt="" />
            <span>微信登录</span>
          </a>
        )}
      </div>
    );
  }
}
