import React from 'react';
import { Relax } from 'plume2';
import { noop } from 'wmkit';

@Relax
export default class Agreement extends React.Component<any, any> {
  props: {
    relaxProps?: {
      toggleShowAgreement: Function;
      registerContent: string;
    };
  };

  static relaxProps = {
    toggleShowAgreement: noop,
    registerContent: 'registerContent'
  };

  render() {
    const { toggleShowAgreement, registerContent } = this.props.relaxProps;
    return (
      <div className="agree-content" onTouchMove={e=> e.stopPropagation()}>
        <a
          href="javascript:void(0);"
          className="closeBox"
          onClick={() => toggleShowAgreement()}
        >
          <i className="iconfont icon-jiantou" />
        </a>
        <div
          className="agree-box"
          dangerouslySetInnerHTML={{ __html: registerContent }}
        />
      </div>
    );
  }
}
