import React from 'react';
import { history } from 'wmkit';

const noneImg = require('../../images/none.png');

export default class GiftList extends React.PureComponent<any, any> {
  render() {
    const { data, shipMode, style } = this.props;
    return (
      <div style={style}>
        {data && data.length > 0 && (
          <div className="gift-box">
            <div>
              {data.map((item) => (
                <div>
                <div
                  className="goods-list"
                  onClick={() => history.push('/goods-detail/' + item.skuId)}
                >
                  <img
                    className="img-box"
                    src={item.pic ? item.pic : noneImg}
                  />
                  <div className="detail">
                    <a className="title" href="#">
                      <div className="tag-gift">赠品</div>
                      {shipMode ? item.itemName : item.skuName}
                    </a>
                    {/* <p className="gec">
                    {item.specDetails ? item.specDetails : ''}{' '}
                  </p> */}
                    <div className="bottom">
                      {!shipMode && (
                        <span className="price">
                          <i
                            className="iconfont icon-qian"
                            style={{ fontSize: '.24rem' }}
                          />
                          0.00
                        </span>
                      )}
                      <span className="num">
                        ×{shipMode ? item.itemNum : item.num}
                        {item.unit ? item.unit : ''}
                      </span>
                    </div>
                    {shipMode && <div>
                      <div className="produce-date">批号：{item.batchNo ? item.batchNo : '无'}</div>
                      <div className="produce-date">注册证号：{item.license ? item.license : '无'}</div>
                      <div className="produce-date">生产日期：{item.produceDate ?item.produceDate : '无'}</div>
                      <div className="produce-date">失效日期：{item.validDate ?item.validDate : '无'}</div>
                    </div>}
                  </div>
                </div>
                </div>

              ))}
            </div>
          </div>
        )}
      </div>
    );
  }
}
