import React from 'react';
import { Relax } from 'plume2';
import { Link } from 'react-router-dom';
import { history } from 'wmkit';

export default class GrouponSearch extends React.Component<any, any> {
  render() {
    const { keyWords, iptStyle, searchStyle } = this.props;

    return (
      <div className="search-box" style={searchStyle ? searchStyle : {}}>
        <div
          className="input-box"
          style={iptStyle ? iptStyle : {}}
          onClick={() =>
            history.push({
              pathname: '/search',
              state: { queryString: keyWords, key: 'groupon' }
            })
          }
        >
          <img
            src={require('./img/search.png')}
            style={{ width: '.32rem', height: '.32rem', marginTop: '.1rem' }}
          />
          {/* <i className="iconfont icon-sousuo" /> */}
          <input type="text" value={keyWords} placeholder="请输入搜索关键词" />
        </div>
        <div />
      </div>
    );
  }
}
