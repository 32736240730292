import {Fetch} from 'wmkit';

/**
 * 分享商品增加成长值及积分
 */
export const shareGoods = () => {
  return Fetch('/customer/points/share', {
    method: 'POST'
  });
};


/**
 * 插入分享记录
 */
export const shareRecord = (recored) => {
  return Fetch('/customersharerecord/add', {
    method: 'POST',
    body: JSON.stringify(recored)
  });
};

/**
 * 优购码分享次数记录
 */
export const updateShareCount = (id) => {
  return Fetch(`/smallshopcoupon/updateShareCount/${id}`, {
    method: 'POST'
  });
};