import React, { Component } from 'react';
import { noop } from 'wmkit';

export interface ActionSheetProps {
  title?: String;
  buttons?: any;
  //关闭弹窗
  close?: () => void;
  //是否可以点击蒙层关闭弹窗
  maskClose?: boolean;
}

export default class ActionSheet extends Component<ActionSheetProps, any> {
  static defaultProps = {
    title: '',
    buttons: [],
    close: noop,
    maskClose: true
  };

  render() {
    const { title, buttons, close, maskClose } = this.props;
    return (
      <div>
        <div className="mask" onClick={maskClose ? close : null} />
        <div className="poper-confirm action-poper">
          {title && <div className="poper-title">{title}</div>}
          <div className="action-sheet">
            {buttons &&
              buttons.map((it) => (
                <div
                  style={it.style}
                  onClick={() => {
                    it.fn();
                    close();
                  }}
                  className="action-button"
                >
                  {it.text}
                </div>
              ))}
          </div>
        </div>
      </div>
    );
  }
}
