import React from 'react';

/**
 * 公共tips
 */

export interface IInputProps {
  iconName?: string;
  text?: string;
  border?: boolean;
  styles?: string;
  styles2?: string;
  bgStyle?: Object;
}

export default class Tips extends React.PureComponent<IInputProps, any> {
  constructor(props: IInputProps) {
    super(props);
  }

  render() {
    const { iconName, text, border, styles, styles2, bgStyle } = this.props;
    // icon-cuo  错误
    // icon-jinggao-copy 警告
    // icon-dui 正确
    // 以上仅提示图标classname，可删除
    return (
      <div
        className={`local-tips ${styles} ${border ? 'bottom-border' : ''}`}
        style={bgStyle ? bgStyle : {}}
      >
        <i className={`iconfont ${iconName} ${styles2} `} />
        <span>{text}</span>
      </div>
    );
  }
}
