/**
 * 封装一个React的slider组件
 * 用法
 * <div className="slider">
 *   <Slider data={data:List}/>
 * </div>
 */
import React, { Component } from 'react';
import Hammer from 'hammerjs';
import { fromJS, List } from 'immutable';
import { WMVideo } from 'wmkit';
import wrapUrl from 'wmkit/image-util/wrap-url';

//放大
let ZoomImage = null;
const videoJsOptions = {
  autoplay: false,
  controls: true,
  playsinline: true, //ios端行内播放视频
  allowFullScreen: false,
  //loop: true, //使视频一结束就重新开始。
  language: 'chz', //设置语言
  sources: [
    {
      src: 'http://oxyi0vk1f.bkt.clouddn.com/evn4.mp4',
      type: 'video/mp4'
    }
  ],
  controlBar: {
    fullscreenToggle: false //是否显示全屏按钮
  }
};

/**
 *
 */
export default class Slider extends Component<any, any> {
  //slider container
  sliderEl: any;
  //hammerjs管理器
  sliderManager: any;
  //动画计时器
  timer: any;
  // 自动切换定时器
  sliderTimer: any;

  props: {
    data: List<any>;
    //以px 结尾
    width: string;
    height: string;
    className?: string;
    //多少百分比的时候进行分页显示
    sensitivity?: number;
    //显示的图片
    activeSlide?: number;
    zoom?: boolean;
    // 图片自动切换时间间隔，毫秒数，正整数有效
    slideInterval?: number;
    //是否有视频播放
    videoPlay?: boolean;
    //视频信息
    videoJsOptions?: any;
    renderVideo?:boolean;
    renderSlider?:boolean
  };

  static defaultProps = {
    data: fromJS([]),
    sensitivity: 5,
    activeSlide: 0,
    zoom: false,
    slideInterval: 0,
    videoPlay: false,
    renderVideo:false,
    renderSlider:false
  };

  state: {
    activeSlide: number;
    // 图片滚动位置：为了实现首尾轮播无缝切换，在图片列表的头和尾分别插入了一张图片，slidePosition 按照列表长度+2进行计算
    slidePosition: number;
    doPlay: boolean;
    paused: boolean;
  };

  constructor(props) {
    super(props);
    this.state = {
      activeSlide: props.activeSlide,
      slidePosition: 0,
      doPlay: false,
      paused: true
    };
    if (props.zoom) {
      System.import('./image-util/zoom-image').then((zoomImage) => {
        ZoomImage = zoomImage;
      });
    }
  }

  componentDidMount() {
    this.init();
  }

  componentWillUnmount() {
    this.sliderManager && this.sliderManager.destroy();
    //手动删除，不删会导致重进保留上次样式，估计preact问题
    this.sliderEl.remove();
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.renderVideo!=this.props.renderVideo){
            this.setState({
                doPlay:false,
            })
    }
    if(nextProps.renderSlider!=this.props.renderSlider){
       this.goTo(0)
    }
  }

  render() {
    const { activeSlide } = this.state;
    const { className, data, height, width } = this.props;
    let count = data.count();
    // 图片列表
    let images = data.toArray().map((src, index) => {
      //只加载当前和下一张图片，减少网络请求
      let showImg = index <= activeSlide + 1;
      let url = showImg
        ? `${wrapUrl({
            src,
            height,
            width
          })}`
        : 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==';
      return this._getImgDiv(url, src, index);
    });

    // 如果数量大与一张，images数组首尾各拼接一张图片，用于首尾轮播无缝切换
    if (count > 1) {
      // 第一张图地址
      const firstImageUrl = `${wrapUrl({ src: data.get(0), height, width })}`;
      // 最后一张图地址
      const lastImageUrl = `${wrapUrl({
        src: data.get(count - 1),
        height,
        width
      })}`;

      images.unshift(
        this._getImgDiv(lastImageUrl, data.get(count - 1), count - 1)
      );
      images.push(this._getImgDiv(firstImageUrl, data.get(0), 5));
    }

    return (
      <div className="slider" style={{ height }}>
        {/*下方的点*/}
        <ul className="slider-pointer">
          {data.toArray().map((url, index) => {
            return (
              <li className={`${index == activeSlide ? 'cur' : ''}`}>
                <span className="dot" />
              </li>
            );
          })}
        </ul>

        <div
          id="slider-panel-container"
          style={{
            width: `${(count > 1 ? count + 2 : count) * 100}%`,
            display: 'flex',
            height: height,
            position: 'relative',
            left: `${(count > 1 ? -1 : 0) * 100}%`
          }}
          ref={(slider) => (this.sliderEl = slider)}
        >
          {images}
        </div>
      </div>
    );
  }

  // 获取图片div
  _getImgDiv = (url, originUrl, index) => {
    const { height, videoPlay } = this.props;
    const { activeSlide, doPlay } = this.state;
    return index == 0 ? (
      <div
        className="slider-panel"
        style={{
          backgroundImage: `url(${url})`,
          backgroundPosition: 'center center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'auto 100%',
          height: height,
          flex: '1',
          position: 'relative' as any
        }}
        onClick={() => {
          this._clickImage(originUrl);
        }}
      >
        {index == 0 &&
          videoPlay && (
            <div
              id="playButton"
              style={{ display: doPlay ? 'none' : 'block' }}
              onClick={(e) => this._doPlay(e)}
            />
          )}
          {
            doPlay && 
            <WMVideo            
            paused={this.state.paused}
            visible={doPlay && index == 0 && activeSlide == 0}
            closeVideo={() => this.setState({ doPlay: false, paused: true })}
            {...this.props.videoJsOptions}
          />
          }  
      </div>
    ) : (
      <div
        className="slider-panel"
        style={{
          backgroundImage: `url(${url})`,
          backgroundPosition: 'center center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'auto 100%',
          height: height,
          flex: '1'
        }}
        onClick={() => {
          this._clickImage(originUrl);
        }}
      />
    );
  };

  init = () => {
    let { data, sensitivity, slideInterval } = this.props;
    let slideCount = data.count();

    // 只有一张图时，无轮播效果
    if (slideCount <= 1) {
      return;
    }

    this.sliderManager = new Hammer(this.sliderEl);

    this.sliderManager.add(new Hammer.Pan({ threshold: 0, pointers: 0 }));
    this.sliderManager.on('pan', (e) => {
      clearTimeout(this.sliderTimer);

      let { activeSlide } = this.state;

      // 移动的百分比
      var percentage =
        ((100 / (slideCount + 2)) * e.deltaX) / window.innerWidth;

      // 计算非第一屏时百分比
      var percentageCalculated =
        percentage - (100 / (slideCount + 2)) * activeSlide;

      // 设置移动
      this.sliderEl.style.transform =
        'translate3d( ' + percentageCalculated + '%, 0, 0 )';

      //结束移动时触发
      if (e.isFinal) {
        if (e.velocityX > 1) {
          this.goTo(activeSlide - 1);
        } else if (e.velocityX < -1) {
          this.goTo(activeSlide + 1);
        } else {
          if (percentage <= -(sensitivity / slideCount))
            this.goTo(activeSlide + 1);
          else if (percentage >= sensitivity / slideCount)
            this.goTo(activeSlide - 1);
          else this.goTo(activeSlide);
        }
      }
    });

    // 触发轮播
    if (slideInterval > 0) {
      this.sliderTimer = setTimeout(() => {
        this.goTo(1);
      }, slideInterval);
    }
  };

  goTo = (number) => {
    //滑动时，如果之前点击过播放视频，则视频需暂停
    if (number != 0) {
      if (this.state.doPlay) {
        this.setState({
          paused: true
        });
      }
    } else {
      //滑回来时，如果之前点击过视频，则视频要播放
      if (this.state.doPlay) {
        this.setState({
          paused: false
        });
      }
    }
    let activeSlide = 0;
    let count = this.props.data.count();
    let slidePosition;

    if (number < 0) {
      activeSlide = count - 1;
      slidePosition = 0;
    } else if (number > count - 1) {
      activeSlide = 0;
      slidePosition = number + 1;
    } else {
      activeSlide = number;
      slidePosition = number + 1;
    }

    let percentage = -(100 / (count + 2)) * (slidePosition - 1);

    this.sliderEl.style.transform = 'translate3d( ' + percentage + '%, 0, 0 )';
    this.sliderEl.style.transition =
      'transform 400ms cubic-bezier(0.5, 0, 0.5, 1)';

    clearTimeout(this.timer);

    this.timer = setTimeout(() => {
      this.sliderEl.style.transition = '';

      // 如果已经到最后一张隐藏图了，跳到可见图的第一张
      if (slidePosition === count + 1) {
        this.sliderEl.style.transform = 'translate3d(0%, 0, 0)';
        slidePosition = 0;
      }
      // 如果已经到第一张隐藏图，跳到可见图的最后一张
      else if (slidePosition === 0) {
        this.sliderEl.style.transform =
          'translate3d(' + -(100 / (count + 2)) * (count - 1) + '%, 0, 0)';
        slidePosition = count;
      }

      this.setState({ activeSlide, slidePosition });

      let { slideInterval } = this.props;
      if (slideInterval > 0) {
        // 动画结束，准备下一次切换图片
        this.sliderTimer = setTimeout(() => {
          this.goTo(activeSlide + 1);
        }, slideInterval);
      }
    }, 400);
  };

  /**
   * 放大图片
   */
  _clickImage = (src) => {
    if (this.props.zoom) {
      ZoomImage.renderZoomImage({ src });
    }
  };

  //播放视频
  _doPlay = (e) => {
    this.setState({
      doPlay: true
    });
    e.stopPropagation()    
  };
}
