/*
 * @Author: lisong[of2601] 
 * @Date: 2017-05-11 09:13:24
 * 放大图片
 */
import React, { PureComponent } from 'react'
import ReactDOM from 'react-dom'
import { msg } from 'plume2'

import Image, { TImage } from './image'


type TZoomImage = TImage & { close?: () => void }

export default class ZoomImage extends PureComponent<TZoomImage, any> {
  _imageTag: any;

  constructor(props) {
    super(props)
  }

  componentWillMount() {
    msg.on('image: close', this.closeImg)
  }

  componentWillUnmount() {
    msg.off('image: close', this.closeImg)
  }

  closeImg = () => {
    this.props.close()
  }

  render() {
    const { close } = this.props;

    return (
      <div className="view-large" onClick={close}>
        <div className="mask"></div>
        <div className="img-area">
          <Image {...this.props as any}
            mode='mfit'
            onLoad={(e) => this._caculateMarginTop(e.target)}
            style={{ 'margin-top': '0px', overflowY: 'scroll' }}
            ref={(imageTag) => {
              this._imageTag = imageTag
            }} />
        </div>
      </div>
    )
  }

  //计算距上高度  
  _caculateMarginTop = (img) => {
    const container = img.parentNode;
    //图片高度小于容器时，设置上边距
    if (img.offsetHeight < container.offsetHeight) {
      img.style.marginTop = ((container.offsetHeight - img.offsetHeight) >> 1) + 'px';
    }
  }
}


export const renderZoomImage = (props: { src: string }, container?: HTMLElement) => {
  if (!container) {
    container = document.createElement('div');

    //防止后退不刷新
    const app = document.getElementById('app');
    (app.firstElementChild || app).appendChild(container);
  }

  const close = () => {
    // ReactDOM.unmountComponentAtNode(container);//和22行重复。
    container.remove();
  }

  ReactDOM.render(<ZoomImage {...(props as any) } close={close} />, container);
} 
