import React from 'react';

export interface IFormItemProps {
  labelName?: string;
  placeholder?: string | React.ReactElement<any>;
  // 输入框样式
  textStyle?: Object;
  styleBox?: Object;
  icon?: Boolean;
  phone?: Boolean;
  placeholderStyle?: Object;
  formTextStyle?: Object;
}

/**
 * form中普通展示项
 */
export default class FormItem extends React.Component<IFormItemProps, any> {
  constructor(props: IFormItemProps) {
    super(props);
  }

  render() {
    const {
      labelName,
      placeholder,
      textStyle,
      styleBox,
      icon,
      phone,
      placeholderStyle,
      formTextStyle
    } = this.props;
    return (
      <div className="row form-item" style={styleBox ? styleBox : {}}>
        <span className="form-text" style={formTextStyle ? formTextStyle : {}}>
          {icon ? (
            <img
              src={phone ? require('../img/3.png') : require('../img/4.png')}
              style={{ width: '.5rem', height: '.5rem' }}
            />
          ) : (
            labelName
          )}
        </span>
        <div className="form-context" style={placeholderStyle}>
          <span className="select-text" style={textStyle}>
            {placeholder}
          </span>
        </div>
      </div>
    );
  }
}
