import wx from 'weixin-js-sdk';
import { _, Fetch, WMkit } from 'wmkit';
import { cache, config } from 'config';
import { suffixForShare } from 'wmkit/common/util';
import { shareGoods } from '../webapi';

export const wxShare = (data) => {
  if (!_.isWeixin()) {
    return;
  }
  data.url = location.href;
  Fetch('/third/share/weChat/getSign', {
    method: 'POST',
    body: JSON.stringify({ url: data.url })
  }).then((res) => {
    if (res.code == config.SUCCESS_CODE) {
      if (data.imgUrl) {
        data.imgUrl = suffixForShare(data.imgUrl);
      }
      share(res.context, data);
    }

  });
};

const share = (info, data) => {
  let { url, title, imgUrl, desc, afterSuccess, onCancel } = data;
  const loginData: any = JSON.parse(localStorage.getItem(cache.LOGIN_DATA));
  if (loginData) {
    const customerId = loginData.customerId;
    if (url.indexOf('?') != -1) {
      url = url + '&shareUserId=' + customerId;
    } else {
      url = url + '?shareUserId=' + customerId;
    }
  }

  const { appId, timestamp, nonceStr, signature } = info;
  let titleDesc;
  if (title || title === '') {
    titleDesc = title + desc;
  } else {
    titleDesc = title + '，' + desc;
  }
  wx.config({
    debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
    appId: appId, // 必填，公众号的唯一标识
    timestamp: timestamp, // 必填，生成签名的时间戳
    nonceStr: nonceStr, // 必填，生成签名的随机串
    signature: signature, // 必填，签名，见附录1
    jsApiList: ['onMenuShareTimeline', 'onMenuShareAppMessage'] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
  });

  wx.ready(() => {
    wx.onMenuShareTimeline({
      title: titleDesc, // 分享标题
      link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
      imgUrl: imgUrl, // 分享图标
      success: function () {
        // afterSuccess();
        if (WMkit.isLogin()) {
          shareGoods();
        }
      },
      cancel: function () {
        // onCancel();
      }
    });
    //分享给朋友
    wx.onMenuShareAppMessage({
      title: title, // 分享标题
      desc: desc, // 分享描述
      link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
      imgUrl: imgUrl, // 分享图标
      // type: '', // 分享类型,music、video或link，不填默认为link
      // dataUrl: '', // 如果type是music或video，则要提供数据链接，默认为空
      success: function () {
        // 用户确认分享后执行的回调函数
        // afterSuccess();
        if (WMkit.isLogin()) {
          shareGoods();
        }
      },
      cancel: function () {
        // 用户取消分享后执行的回调函数
        // onCancel();
      }
    });
  });
};
