import React from 'react';
import { WMImage, WMkit, noop } from 'wmkit';

const noneImg = require('./img/none.png');
const windowWidth = window.innerWidth.toString() + 'px';
import * as _ from '../common/util';
import { isFlashSale } from '../../../src/goods-detail/webapi';
import { history } from 'wmkit';

const IS_ACCOUNT_STATUS = {
  0: '待入账',
  1: '已入账',
  2: '入账失败'
};

export default class SkuList extends React.PureComponent<any, any> {
  static defaultProps = {
    //样式
    style: {},
    //选项数据
    data: [],
    // 是否自营
    isSelf: false,
    //是否显示返利
    rebate: false,
    // 返利图标字样(0:赚 1:返)
    commissionStrType: 0,
    // 是否显示返利图标
    showCommissionStr: true,
    showRegular: false,
    addToRegularPurchase: noop
  };

  render() {
    const { style, data } = this.props;
    return (
      <div
        style={{
          background: '#fafafa',
          ...style
        }}
      >
        {data.map((sku) => this._buildSkuItem(sku))}
      </div>
    );
  }

  /**
   * 构建sku结构
   * @param sku
   * @returns {any}
   * @private
   */
  _buildSkuItem = (sku) => {
    const {
      imgUrl,
      subType,
      skuName,
      specDetails,
      price,
      points,
      num,
      unit,
      distributionGoodsAudit,
      distributionCommission,
      pointsGoodsId,
      isAccountStatus,
      skuId,
      registerCode,
      batchNo
    } = sku;
    const {
      isSelf,
      rebate,
      commissionStrType,
      showCommissionStr,
      showRegular,
      addToRegularPurchase
    } = this.props;
    let commissionFlag = false;
    if (WMkit.isDistributorLogin() && distributionGoodsAudit == 2) {
      // 小b登录时，分销商品显示返利
      commissionFlag = true;
    }
    return (
      <div className="goods-list" style={{ marginTop: '.2rem' }}>
        <img
          className="img-box"
          onClick={() => history.push(`/goods-detail/${skuId}`)}
          src={imgUrl ? imgUrl : noneImg}
        />
        <div className="detail b-1px-b">
          <div className="rowTitle">
            <a className="title " href="#">
              {skuName}
            </a>
            {(isAccountStatus || isAccountStatus == 0) && (
              <span className="isAccountStatus">
                {IS_ACCOUNT_STATUS[isAccountStatus]}
              </span>
            )}
          </div>
          {/* <p className="gec">{specDetails ? specDetails : ''}</p> */}
          {/*注册证号*/}
          {registerCode && <p className="gec">{registerCode}</p>}
          {/*注册证号放大暂时先写死*/}
          {/* <WMImage
            src={'https://hzyhgly01.oss-cn-hangzhou.aliyuncs.com/202005110928454779.jpg'}
            width="53px"
            height="53px"
            ifZoom={true}
          /> */}
          <div className="marketing">
            {!WMkit.isShop() && !rebate && isSelf == true && (
              <div className="self-sales">自营</div>
            )}
            {showRegular && (
              <div
                className="regular-purchase-goods"
                onClick={() => addToRegularPurchase(sku)}
                style={{ marginLeft: 0 }}
              >
                常购
              </div>
            )}
            {commissionFlag && rebate && !pointsGoodsId && (
              <span className="rebate">
                {showCommissionStr && (
                  <span className="rebate-bg">
                    {commissionStrType == 0 ? '赚' : '返'}
                  </span>
                )}
                <i className="iconfont icon-qian" />
                {distributionCommission.toFixed(2)}
              </span>
            )}
            {/* <div className="tag">减</div>
            {/*<div className="tag red">赠</div>*/}
            {/*展示下单时商品促销活动标签；*/}
            {subType && <div className="self-sales">{subType}</div>}
          </div>

          <div className="bottom">
            {pointsGoodsId ? (
              <span className="price">{points}积分</span>
            ) : (
              <span className="price">
                <i
                  className="iconfont icon-qian"
                  style={{ fontSize: '.24rem' }}
                />
                {_.addZero(price)}
              </span>
            )}
            <span className="num">
              ×{num}
              {unit ? unit : ''}
            </span>
          </div>
        </div>
      </div>
    );
  };
}
