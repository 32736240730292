import React from 'react';
import { Relax } from 'plume2';
import { UploadImage, noop, WMImage, Alert } from 'wmkit';

/**
 * 上传的营业执照附件
 */
@Relax
export default class EnterpriseFile extends React.Component<any, any> {
  ZoomImage: any;
  props: {
    relaxProps?: {
      tid: string;
      addImage: Function;
      removeImage: Function;
      images: any;
      enterpriseCheckState:string;
    };
  };

  static relaxProps = {
    tid: 'tid',
    addImage: noop,
    removeImage: noop,
    images: 'images',
    enterpriseCheckState:'enterpriseCheckState'
  };

  constructor(props) {
    super(props);
    System.import('../../image-util/zoom-image').then((zoomImage) => {
      this.ZoomImage = zoomImage;
    });
  }

  render() {
    const { images,enterpriseCheckState } = this.props.relaxProps;

    return (
      <div className="enterprise-file-box">
        <span className="form-text">
            <i style={{color:'#ff1f4e'}}>*</i>上传营业执照
        </span>
        <div className="image-scroll">
          {this.props.relaxProps.images.toArray().map((v, index) => {
            return (
              <div className="enterprise-file delete-img">
                <WMImage
                  src={v.get('fileData') ? v.get('fileData') : v.get('image')}
                  width="53px"
                  height="53px"
                  ifZoom={true}
                />
                {
                  enterpriseCheckState != '1' &&
                  <a
                    href="javascript:void(0)"
                    onClick={() => this.removeImage(index)}
                  >
                    ×
                  </a>
                }
              </div>
            );
          })}

          {images.count() < 1 ? (
            <div className="enterprise-file">
              <UploadImage
                onSuccess={this._uploadImage}
                onLoadChecked={this._uploadCheck}
                repeat
                className="upload"
                size={2}
                fileType=".jpg,.png,.jpeg,.gif"
              >
                <div className="upload-item">
                  <div className="upload-content">
                    <i className="iconfont icon-add11" />
                  </div>
                </div>
              </UploadImage>
            </div>
          ) : null}
        </div>
        <p className="enterprise-register-file-tips">
          <img style={{verticalAlign: 'middle'}} onClick={()=>{
            this._showDemoImg()
          }} src={require('../img/ask-01.png')} />
          &nbsp;&nbsp;仅支持jpg、jpeg、png、gif文件，最多上传1张，大小不超过2M
        </p>
      </div>
    );
  }

  _showDemoImg = () => {
    this.ZoomImage.renderZoomImage({ src :'https://timgsa.baidu.com/timg?image&quality=80&size=b9999_10000&sec=1583498570465&di=d4b7760c2780bdbdc6d2db9c886a9ae5&imgtype=0&src=http%3A%2F%2F5b0988e595225.cdn.sohucs.com%2Fq_70%2Cc_zoom%2Cw_640%2Fimages%2F20190227%2Fffd8794b46574f27bf50ba40c59e2fe0.jpeg' });
  };

  /**
   * 上传附件
   */
  _uploadImage = (result, file, data) => {
    let { context } = result;
    if (context) {
      this.props.relaxProps.addImage({
        image: context[0],
        status: 'done',
        fileData: data
      });
    }
  };

  /**
   * 删除附件
   */
  removeImage = (index) => {
    this.props.relaxProps.removeImage(index);
  };

  /**
   * 上传前校验
   * @returns {boolean}
   * @private
   */
  _uploadCheck = () => {
    const { images } = this.props.relaxProps;
    if (images.count() >= 1) {
      Alert({ text: '最多只能上传1张图片' });
      return false;
    }
    return true;
  };
}
