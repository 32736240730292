import React from 'react';
import ReactDOM from 'react-dom';
import { msg } from 'plume2';

/**
 * 图片列表，可左右滑动查看全部
 */
export default class ImageListScroll extends React.Component<any, any> {
  //放大
  ZoomImage: any;

  props: {
    imageList: Array<any>; // 图片列表
    size?: number; // 图片尺寸大小
    labelName?: string; // 列表名称
    style?: object; // 样式
    labelStyle?: Object;
  };

  static defaultProps = {
    size: 55
  };

  constructor(props) {
    super(props);
    System.import('../image-util/zoom-image').then((zoomImage) => {
      this.ZoomImage = zoomImage;
    });
  }

  componentWillUnmount() {
    msg.emit('image: close');
  }

  render() {
    const { imageList, size, labelName, style, labelStyle } = this.props;
    const attachmentInner = {
      ...styles.picInner,
      ...{ width: imageList.length * (size + 10) }
    };

    return (
      <div style={style ? style : null} className="pic-box">
        {labelName ? <h4 style={labelStyle}>{labelName}</h4> : null}
        <div className="pic-outer">
          <div style={attachmentInner}>
            {imageList.length > 0 ? (
              imageList.map((v, index) => {
                return (
                  <img
                    src={v.image}
                    className="pic-img"
                    onClick={() => this._showImage(v.image, index)}
                  />
                );
              })
            ) : (
              <h4 style={{ color: '#999' }}>无</h4>
            )}
          </div>
        </div>
      </div>
    );
  }

  _showImage = (src, index) => {
    this.ZoomImage.renderZoomImage({ src });
  };
}

const styles = {
  picInner: {
    width: '100%',
    marginTop: '10px'
  } as React.CSSProperties
};
