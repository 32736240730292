import React from 'react';

const noop = () => {};

export interface IFormSelectProps {
  //项目名
  labelName?: string;
  onPress?: Function;
  // 选中后的值
  selected?: ISelected;
  placeholder?: string;
  textStyle?: Object;
  //icon 是否显示
  iconVisible?: boolean;
}

//选终值
export interface ISelected {
  key: any;
  value: string;
}

/**
 * form中选中
 */
export default class FormSelectReq extends React.Component<
  IFormSelectProps,
  any
> {
  static defaultProps = {
    onPress: noop,
    iconVisible: true
  };
  constructor(props: IFormSelectProps) {
    super(props);
  }

  render() {
    const {
      labelName,
      onPress,
      selected,
      placeholder,
      textStyle,
      iconVisible
    } = this.props;
    return (
      <div className="row form-item" onClick={() => onPress()}>
        <span className="form-text">
          <i style={{ color: '#bd2d27' }}>*</i>
          {labelName}
        </span>
        <div className="select-right">
          <span className="select-text" style={textStyle}>
            {selected && selected.value ? selected.value : placeholder}
          </span>
          {iconVisible ? <i className="iconfont icon-jiantou1" /> : null}
        </div>
      </div>
    );
  }
}
