//@ts-ignore
export const config =  __ENVINFO__ as {
  BFF_HOST:string;
  RENDER_HOST:string;
  OSS_HOST:string;
  PV_UV_HOST:string;
  IMAGE_SERVER:string;
  SUCCESS_CODE:string;
  HTTP_TIME_OUT: number,
};
