import React from 'react';
import { noop } from 'wmkit';

/**
 * 公共RadioBox单选按钮(方)
 */
export default class RadioBox extends React.PureComponent<any, any> {
  static defaultProps = {
    //选中值
    checked: 0,
    //选择方法
    onCheck: noop,
    //样式
    style: {},
    //选项数据
    data: [],
    //判断是否大勾
    isGou: true
  };

  render() {
    const { checked, style, data, isGou, selectStyle } = this.props;
    return (
      <div className="select-reason" style={selectStyle ? selectStyle : {}}>
        {data.map((val) => {
          return (
            <div
              className={`select-btn ${
                checked == val.id ? 'select-btn-checked' : null
              }`}
              style={{ ...style }}
              onClick={() => this._onCheck(val.id)}
            >
              {val.name}
              {checked == val.id && isGou ? (
                <i className="iconfont icon-gou" />
              ) : null}
            </div>
          );
        })}
      </div>
    );
  }

  _onCheck = (k) => {
    this.props.onCheck(k);
  };
}
