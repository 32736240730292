import Fetch from 'wmkit/fetch';

import { _ } from 'wmkit';
import { config } from 'config';

/**
 * 微信授权登录--请求微信，跳转到微信授权页面
 */
export const getAuth = (url,type) => {
  //type取值，目前有bind和login,意味是绑定唤起微信还是登录唤起微信
  //1 请求后台获得appId
  Fetch('/third/login/wechat/set/detail/MOBILE').then((res) => {
    if (res.code != config.SUCCESS_CODE) {
      return;
    }
    //获取appId
    const appId = (res.context as any).appId;
    const scope = 'snsapi_userinfo';
    //仅表示有无授权
    const state = _.rndNum(10).toString()+type;
    const redirectURL = encodeURIComponent(url);
    // window['wxAuth'] = state;
    //授权地址
    const authURL = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appId}&redirect_uri=${redirectURL}&response_type=code&scope=${scope}&state=${state}#wechat_redirect`;
      window.location.href = authURL;
  });
};
