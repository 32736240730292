import { WMkit } from 'wmkit';
import 'whatwg-fetch';
import { cache } from 'config';
declare let AMap: any;
const isIOS = WMkit.isIOS();
/**
 * 根据经纬度返回详细地址
 * location=116.310003,39.991957
 */
export const getLocationInfo = async (location, isStorage?) => {
  return new Promise((resolve, reject) => {
    fetch(
      `https://restapi.amap.com/v3/geocode/regeo?key=bc4b43258d12d4d54fe9334b207c623f&location=${location}`,
      {
        method: 'GET'
      }
    )
      .then((data) => {
        return data.json();
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        console.log('根据经纬度返回详细地址出错');
        reject(err);
      });
  });
};

/**
 * 获取位置经纬度
 */
export const getLocation_lngAndLat = () => {
  // H5端用web js 高德定位api
  return new Promise((resolve, reject) => {
    AMap.plugin('AMap.Geolocation', () => {
      let geolocation = new AMap.Geolocation({
        enableHighAccuracy: true, // 是否使用高精度定位，默认：true
        timeout: 10000, // 设置定位超时时间，默认：无穷大
        GeoLocationFirst: true // 默认为false，设置为true的时候可以调整PC端为优先使用浏览器定位，失败后使用IP定位
      });
      geolocation.getCurrentPosition(async (status, result) => {
        console.log('====================================');
        console.log(status, 'h5获得经纬度获得成功！：', result);
        console.log('====================================');
        if (status == 'complete') {
          resolve(result);
        } else {
          reject({ status, result });
        }
      });
    });
  });
};

/**
 * 根据详细地址获取经纬度
 */
export const getlngAndLatFromAdress = (address) => {
  // H5端用web js 高德定位api
  return new Promise((resolve, reject) => {
    fetch(
      `https://restapi.amap.com/v3/geocode/geo?key=bc4b43258d12d4d54fe9334b207c623f&s=rsv3&city=35&address=${address}`,
      {
        method: 'GET'
      }
    )
      .then((data) => {
        return data.json();
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        console.log('根据详细地址获取经纬度出错');
        reject(err);
      });
  });
};
